import PropTypes from 'prop-types';
import React from 'react';
import { StaticQuery, graphql } from 'gatsby';

const CareersContainer = ({ children }) => (
  <StaticQuery
    query={graphql`
      query {
        allCareersContentJson {
          edges {
            node {
              bannerImage
              id
              intro_paragraphs {
                para
              }
              is_job
              job_openings {
                details {
                  detail
                }
                careerInformationRegularText
                careerInformationBoldText
                mailToEmailAddress
                submitResumeRegularText
                submitResumeBoldText
                location
                openings
                position
                requirements
                title
                type
                workLocation
              }
              no_current_opportunities
              subtitle
              subtitle_current_opportunities
              subtitle_paragraphs {
                para
              }
              title
              bottomBannerTitle
            }
          }
        }
      }
    `}
    render={({ allCareersContentJson: { edges: careers } }) =>
      children(
        careers.map((e) => ({
          ...e.node,
        }))
      )
    }
  />
);

CareersContainer.propTypes = {
  children: PropTypes.func.isRequired,
};

export default CareersContainer;
