import React from 'react';
import propTypes from 'prop-types';
import Box from '../../base/Box';
import closeIcon from '../../images/icons/close-icon.svg';

const Modal = ({
  blur,
  children,
  isOpen,
  modalHeader,
  modalFooter,
  modalTitle,
  onRequestClose,
  overlay,
  overlaySx,
  sx,
}) => {
  const overlayStyled = {
    bottom: '0',
    left: '0',
    position: 'fixed',
    right: '0',
    top: '0',
    zIndex: '15',
  };
  const bodyPadding = {
    pt: 4,
    px: 4,
  };
  return isOpen ? (
    <>
      {overlay !== false && (
        <Box
          onClick={blur !== false && onRequestClose}
          sx={{
            bg: 'grays.9',
            opacity: '0.5',
            ...overlayStyled,
            ...overlaySx,
          }}
        />
      )}
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          pointerEvents: 'none',
          ...overlayStyled,
        }}
      >
        <Box
          sx={{
            bg: 'white',
            borderRadius: 1,
            display: 'flex',
            flexDirection: 'column',
            maxHeight: 'calc(100% - 20px)',
            maxWidth: 'calc(100% - 20px)',
            overflow: 'hidden',
            pointerEvents: 'all',
            position: 'relative',
            shadow: 2,
            width: '100%',
            zIndex: '20',
            ...sx,
          }}
        >
          <Box
            onClick={onRequestClose}
            sx={{
              cursor: 'pointer',
              height: '10px',
              position: 'absolute',
              right: '5px',
              top: '5px',
              width: '10px',
            }}
          >
            <Box
              as="img"
              src={closeIcon}
              sx={{
                display: 'inline-block',
                verticalAlign: 'top',
                width: '100%',
              }}
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              flex: '1',
              flexDirection: 'column',
              oveFlow: 'hidden',
              pb: 4,
            }}
          >
            {}
            {(modalHeader || modalTitle) && (
              <Box sx={{ ...bodyPadding }}>
                {modalHeader || (
                  <Box
                    as="h2"
                    sx={{
                      color: 'grays.3',
                      fontFamily: 'tertiary',
                      fontSize: 6,
                      fontStyle: 'normal',
                      fontWeight: 'bold',
                      lineHeight: '28px',
                      textTransform: 'uppercase',
                    }}
                  >
                    {modalTitle}
                  </Box>
                )}
              </Box>
            )}
            <Box
              sx={{
                flex: '1',
                overflow: 'auto',
                ...bodyPadding,
              }}
            >
              {children}
            </Box>
            {modalFooter && <Box sx={{ ...bodyPadding }}>{modalFooter}</Box>}
          </Box>
        </Box>
      </Box>
    </>
  ) : null;
};

Modal.propTypes = {
  blur: propTypes.bool,
  children: propTypes.node.isRequired,
  isOpen: propTypes.bool,
  modalFooter: propTypes.node,
  modalHeader: propTypes.node,
  modalTitle: propTypes.string,
  onRequestClose: propTypes.func,
  overlay: propTypes.bool,
  overlaySx: propTypes.shape({}),
  sx: propTypes.shape({}),
};

Modal.defaultProps = {
  blur: true,
  isOpen: false,
  modalFooter: null,
  modalHeader: null,
  modalTitle: '',
  onRequestClose: () => null,
  overlay: true,
  overlaySx: {},
  sx: {},
};

export default Modal;
