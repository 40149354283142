import React from 'react';
import { Router } from '@reach/router';
import NotFoundPage from './404';
import CareersPage from '../components/CareersPage';

const CareerPageRouter = () => {
  return (
    <Router>
      <CareersPage page="careers" path="/careers" />
      <NotFoundPage default />
    </Router>
  );
};

export default CareerPageRouter;
