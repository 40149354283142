import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Box from '../../base/Box';
import SEO from '../SEO';
import Container from '../Container';
import ListGroup from '../ListGroup';
import Title from '../Title';
import Accordion from '../Accordion';
import Button from '../Button';
import Modal from '../Modal';
import ContactForm from '../ContactForm';
import PageBanner from '../PageBanner';
import SubTitle from '../SubTitle';
import CareersContainer from '../../containers/CareersContainer';

const CareersPage = ({ page }) => {
  const [modalToggled, setModalToggled] = useState(false);
  const setListData = (details, title, paras) => {
    return (
      <ListGroup
        key={`data-${title}`}
        paras={paras}
        sx={{
          fontWeight: 'regular',
        }}
        title={title}
        titleSx={{
          color: 'warning',
          fontFamily: 'tertiary',
          fontSize: 5,
          fontStyle: 'normal',
          fontWeight: 'bold',
          lineHeight: '23px',
        }}
        wrapperSx={{
          breakInside: 'avoid',
          color: 'grays.3',
          fontFamily: 'tertiary',
          fontSize: 3,
          fontStyle: 'normal',
          fontWeight: 'regular',
          lineHeight: '28px',
          pageBreakInside: 'avoid',
          pb: 5,
        }}
      >
        {details.length > 0 &&
          details.map((detail, index) => (
            <span key={`intro-${index}`}>{detail.para}</span>
          ))}
      </ListGroup>
    );
  };

  const getTotalOpenings = (openings) =>
    openings > 1 ? '(Multiple openings)' : `(${openings} opening)`;

  return (
    <CareersContainer>
      {(careers) => {
        const pageName = page || 'careers';
        const detailInfo = careers
          .map((ele) => {
            return ele.id === pageName ? ele : null;
          })
          .find((el) => el);
        return (
          <>
            <Box>
              <SEO />
              {/* <SEO description="" title="Careers" /> */}
              <PageBanner image={detailInfo.bannerImage} />
              <Container
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  mb: 10,
                  mt: '46px',
                  // width: 'maxWidths.content',
                  width: ['100%', null, null, '90%', null],
                }}
              >
                <Title
                  sx={{
                    color: 'grays.4',
                    fontFamily: 'tertiary',
                    fontSize: 9,
                    fontStyle: 'normal',
                    fontWeight: 'bold',
                    lineHeight: '35px',
                    mb: 5,
                  }}
                >
                  {detailInfo.title}
                </Title>
                {detailInfo.intro_paragraphs &&
                  detailInfo.intro_paragraphs.length > 0 &&
                  setListData(detailInfo.intro_paragraphs, '', false)}
                {detailInfo.subtitle_paragraphs &&
                  detailInfo.subtitle_paragraphs.length > 0 &&
                  setListData(
                    detailInfo.subtitle_paragraphs,
                    detailInfo.subtitle,
                    false
                  )}
                {!detailInfo.is_job && detailInfo.no_current_opportunities && (
                  <Box
                    sx={{
                      color: 'grays.3',
                      fontFamily: 'tertiary',
                      fontSize: 3,
                      fontStyle: 'normal',
                      fontWeight: 'medium',
                      lineHeight: '18px',
                      mt: '39px',
                    }}
                  >
                    {detailInfo.no_current_opportunities}
                  </Box>
                )}
                {detailInfo.is_job &&
                  detailInfo.subtitle_current_opportunities && (
                    <SubTitle
                      sx={{
                        color: 'warning',
                        fontFamily: 'tertiary',
                        fontSize: 5,
                        fontStyle: 'normal',
                        fontWeight: 'bold',
                        lineHeight: '23px',
                        mb: '25px',
                        mt: '78px',
                      }}
                    >
                      {detailInfo.subtitle_current_opportunities}
                    </SubTitle>
                  )}
                {detailInfo.is_job &&
                  detailInfo.job_openings &&
                  detailInfo.job_openings.length > 0 && (
                    <Box sx={{ mb: -2 }}>
                      {detailInfo.job_openings.map((opening, index) => (
                        <Accordion
                          key={index}
                          AccordionTitle={`${
                            opening.title || opening.position
                          } ${getTotalOpenings(opening.openings)}`}
                          Expandable
                          sx={{
                            fontFamily: 'tertiary',
                            fontSize: 3,
                            mb: 2,
                          }}
                        >
                          {(opening.position || opening.location) && (
                            <ListGroup listSx={{ mb: 4 }} wrapperSx={{ mb: 4 }}>
                              {opening.position && (
                                <span>
                                  Position: <b>{opening.position}</b>
                                </span>
                              )}
                              {opening.openings && (
                                <span>
                                  No of Openings: <b>{opening.openings}</b>
                                </span>
                              )}
                              {opening.location && (
                                <span>
                                  Location: <b>{opening.location}</b>
                                </span>
                              )}
                              {opening.type && (
                                <span>
                                  Type: <b>{opening.type}</b>
                                </span>
                              )}
                            </ListGroup>
                          )}
                          {opening.details && opening.details.length > 0 && (
                            <ListGroup
                              listSx={{ display: 'flex', mb: 4 }}
                              sx={{
                                fontStyle: 'normal',
                                fontWeight: 'regular',
                              }}
                              title="Job Description"
                              titleSx={{
                                fontSize: 3,
                                textTransform: 'Capitalize',
                              }}
                              wrapperSx={{ mb: 6 }}
                            >
                              {opening.details.map((detailObject, index) => (
                                <Box
                                  key={`${
                                    opening.title || opening.position
                                  }-Description-${index}`}
                                  as="p"
                                >
                                  <b>-</b>
                                  <Box as="span" sx={{ ml: 2 }}>
                                    {detailObject.detail}
                                  </Box>
                                </Box>
                              ))}
                            </ListGroup>
                          )}

                          {(opening.requirements || opening.workLocation) && (
                            <ListGroup
                              listSx={{ mb: 4 }}
                              sx={{ fontWeight: 'regular' }}
                              wrapperSx={{ mb: 6 }}
                            >
                              {opening.requirements && (
                                <span>
                                  <b>Requirements: </b> {opening.requirements}
                                </span>
                              )}
                              {opening.workLocation && (
                                <span>
                                  <b>Work Location: </b> {opening.workLocation}
                                </span>
                              )}
                            </ListGroup>
                          )}

                          <ListGroup
                            listSx={{ mb: 4 }}
                            sx={{ fontWeight: 'regular' }}
                            wrapperSx={{ mb: 6 }}
                          >
                            <span>
                              {opening.submitResumeRegularText}{' '}
                              <b>
                                <Box
                                  as="a"
                                  href={`mailto:${opening.mailToEmailAddress}`}
                                  sx={{ color: 'grays.3' }}
                                >
                                  {opening.submitResumeBoldText}
                                </Box>
                              </b>
                            </span>
                            <span>
                              {opening.careerInformationRegularText}{' '}
                              <b>
                                <Box
                                  as="a"
                                  href={`mailto:${opening.mailToEmailAddress}`}
                                  sx={{ color: 'grays.3' }}
                                >
                                  {opening.careerInformationBoldText}
                                </Box>
                              </b>
                            </span>
                          </ListGroup>

                          <Button
                            hoverSx={{ bg: 'grays.3' }}
                            onClick={() => setModalToggled(!modalToggled)}
                            sx={{
                              '&:hover': {
                                bg: 'grays.11',
                                boxShadow: 2,
                                color: 'grays.9',
                              },
                              bg: 'warning',
                              color: 'white',
                              fontFamily: 'secondary',
                              fontSize: 2,
                              fontStyle: 'normal',
                              fontWeight: 'semiBold',
                              height: '23px',
                              lineHeight: '19px',
                              px: 'unset',
                              py: 'unset',
                              width: '88px',
                            }}
                          >
                            Apply
                          </Button>
                        </Accordion>
                      ))}
                    </Box>
                  )}
              </Container>
              <Box
                sx={{
                  bg: 'grays.0',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'flex-end',
                  minHeight: [null, null, null, '263px', null],
                  px: [2, null, null, 'unset', null],
                  py: 7,
                }}
              >
                {detailInfo.bottomBannerTitle && (
                  <Title
                    sx={{
                      color: 'grays.3',
                      fontFamily: 'tertiary',
                      fontSize: 9,
                      fontStyle: 'normal',
                      fontWeight: 'black',
                      lineHeight: [null, null, null, null, '20px'],
                      mb: '19px',
                      textAlign: 'center',
                    }}
                  >
                    {detailInfo.bottomBannerTitle}
                  </Title>
                )}
                <Box
                  sx={{
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <Box
                    sx={{
                      alignItems: 'center',
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    <Button
                      hoverSx={{ bg: 'grays.3' }}
                      onClick={() => setModalToggled(!modalToggled)}
                      sx={{
                        '&:hover': {
                          bg: 'grays.11',
                          boxShadow: 2,
                          color: 'grays.9',
                        },
                        bg: 'warning',
                        color: 'white',
                        fontFamily: 'secondary',
                        fontSize: 4,
                        fontStyle: 'normal',
                        fontWeight: 'semiBold',
                        height: '42px',
                        lineHeight: '25px',
                        px: 'unset',
                        py: 'unset',
                        textAlign: 'center',
                        width: '164px',
                      }}
                    >
                      <span>Apply Now</span>
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Modal
              isOpen={modalToggled}
              modalTitle="Send us your resume"
              onRequestClose={() => setModalToggled(!modalToggled)}
              sx={{
                width: ['100%', null, '650px', null, null],
              }}
            >
              <ContactForm allowFile />
            </Modal>
          </>
        );
      }}
    </CareersContainer>
  );
};

CareersPage.propTypes = {
  page: PropTypes.string,
};

CareersPage.defaultProps = {
  page: '',
};

export default CareersPage;
