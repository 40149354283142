import React, { useState } from 'react';
import propTypes from 'prop-types';
import Box from '../../base/Box';

const Accordion = ({
  AccordionTitle,
  AccordionContent,
  Expandable,
  children,
  sx,
  ...rest
}) => {
  const [toggled, setToggled] = useState(false);
  const toggledSx = toggled
    ? {
        maxHeight: '500rem',
        opacity: 1,
        transform: 'translateY(0)',
        transition:
          'max-height 0.7s ease-in-out 0s, opacity 0.55s ease-in-out 0.15s, transform 0.35s ease 0.15s',
      }
    : {};
  return (
    <Box
      {...rest}
      sx={{
        backgroundColor: 'grays.0',
        borderRadius: 2,
        color: 'grays.3',
        mx: [null, null, null, null, -6],
        overflow: 'hidden',
        position: 'relative',
        px: [4, null, null, null, 6],
        ...sx,
      }}
    >
      <Box
        onClick={() => setToggled(!toggled)}
        sx={{
          alignItems: 'center',
          cursor: 'pointer',
          display: 'flex',
          fontSize: 5,
          fontWeight: toggled ? 'bold' : 'medium',
          justifyContent: 'space-between',
          position: 'relative',
          py: '22px',
        }}
      >
        <Box
          sx={{
            flex: '1',
            pr: 2,
          }}
        >
          {AccordionTitle}
        </Box>
        <Box
          sx={{
            '&:after': {
              borderColor: 'inherit',
              borderRightWidth: 0,
              borderStyle: 'solid',
              borderTopWidth: 0,
              borderWidth: '0 0 1px 1px',
              content: '""',
              height: '9px',
              pointerEvents: 'none',
              transform: 'rotate(-45deg)',
              width: '9px',
            },
            alignItems: 'center',
            display: 'inline-flex',
            height: '18px',
            justifyContent: 'center',
            position: 'relative',
            transform: toggled ? 'rotate(-180deg)' : '',
            transition: 'all 0.5s 0s ease-in-out',
            width: '18px',
          }}
        />
      </Box>
      <Box
        sx={{
          maxHeight: 0,
          opacity: 0,
          overflow: 'hidden',
          transform: 'translateY(20px)',
          transition:
            'max-height 0.7s ease-in-out 0s, opacity 0.35s ease-in-out 0s, transform 0s ease 0.35s',
          ...toggledSx,
        }}
      >
        <Box
          sx={{
            pb: 7,
            pt: 5,
          }}
        >
          {children || AccordionContent || null}
        </Box>
      </Box>
    </Box>
  );
};

Accordion.propTypes = {
  AccordionContent: propTypes.node,
  AccordionTitle: propTypes.node.isRequired,
  children: propTypes.node.isRequired,
  Expandable: propTypes.bool,
  sx: propTypes.shape({}),
};

Accordion.defaultProps = {
  AccordionContent: null,
  Expandable: true,
  sx: {},
};

export default Accordion;
